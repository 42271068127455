import { Getter, Setter } from '../services/ComService'

export default class SpecialExpenseDTO {
  id: number = -1
  clientId: number = -1
  name: string = ''
  date: Date = new Date()
  cost: number = 0

  constructor(data: ISpecialExpense) {
    this.initData(data)
  }

  private initData(data: ISpecialExpense) {
    this.id = data.id || -1
    this.clientId = data.clientId
    this.name = data.name
    this.date = new Date(data.date)
    this.cost = data.cost
  }

  public async publicGetFromServer() {
    let responseJson = await Getter('expense/' + this.id)
    this.initData(responseJson.expense)
  }

  get() {
    return {
      id: this.id,
      clientId: this.clientId,
      name: this.name,
      date: this.date,
      cost: this.cost
    }
  }

  public async save() {
    let response
    if (this.id > 0) {
      response = await Setter(`expense/${this.id}/edit`, this.get())
    } else {
      response = await Setter(`client/${this.clientId}/addExpense`, this.get())
    }
    // client/{clientid}/addExpense
    this.initData(response.expense)
  }

  public async remove() {
    await Getter(`expense/${this.id}/delete`)
  }
}

export interface ISpecialExpense {
  id?: number,
  clientId: number,
  name: string,
  date: string,
  cost: number
}
