import React from 'react'
import { Getter } from './services/ComService'
import MainService from './services/MainService'
// import UserDTO from './DTO/UserDTO'
import InputA from './components/InputA/InputA'
import Clients from './components/Clients/Clients'
import ClientEdit from './components/Clients/ClientEdit'
import Projects from './components/Projects/Projects'
import SpecialExpenses from './components/SpecialExpenses/SpecialExpenses'
import Stamps from './components/Stamps/Stamps'
import Analysis from './components/Analysis/Analysis'
// import config from './config.json'
import { StopCircle } from 'react-feather'

enum Mode {
  stamps,
  analysis,
  editClient
}

type Props = {}

type State = {
  userName: string,
  loginName: string,
  loginPwd: string,
  mode: Mode,
  clientId: number
}

export default class Main extends React.Component<Props, State> {
  mainService: MainService
  constructor(props: Props) {
    super(props)
    this.state = {
      userName: 'nobody',
      loginName: '',
      loginPwd: '',
      mode: Mode.stamps,
      clientId: -1
    }
    this.mainService = new MainService()
  }

  componentDidMount() {
    this.mainService.registerToBroadcast(
      'mainComponent',
      this.broadcastReceiver,
      this
    )
  }

  componentWillUnmount() {
    this.mainService.unregisterBroadcast('mainComponent')
  }

  broadcastReceiver(key: string, value: any, self: any) {
    switch(key) {
      case 'editClient':
        self.setState({
          mode: Mode.editClient,
          clientId: value as number
        })
        break
    }
  }

  renderLoginScreen() {
    return <>
      <div>
        <InputA
          mainservice={this.mainService}
          returnVal={(name: string) => {
            console.log('name', name)
            this.setState({loginName: name})
          }}
          value={this.state.loginName}
          parent={this}
          />
      </div>
      <div>
        <InputA
          mainservice={this.mainService}
          returnVal={(pwd: string) => {
            console.log('pwd', pwd)
            this.setState({loginPwd: pwd})
          }}
          value={this.state.loginPwd}
          parent={this}
          />
      </div>
      <button
        onClick={() => {
          this.mainService.user.login(this.state.loginName, this.state.loginPwd).then((result) => {
            console.log('login result')
            this.setState({userName: result.name as string})
          })
        }}
        >Login</button>
    </>
  }
  render() {
    if (this.state.userName === 'nobody') {
      // return this.renderLoginScreen()
    }
    // You are: {this.state.userName}
    return <>
      <button
        onClick={() => {
          Getter('stop').then(() => {
            this.mainService.broadcast('stop', true)
          })
        }}
      >
        <StopCircle />
      </button>
      <button
        onClick={() => {
          this.setState({mode: Mode.stamps})
        }}
      >Stempeluhr</button>
      <button
        onClick={() => {
          this.setState({mode: Mode.analysis})
        }}
      >Auswertung</button>

      {
        this.state.mode === Mode.stamps &&
        <div
          className='w3-row'
        >

          <Clients mainservice={this.mainService} />
          <Projects mainservice={this.mainService} />
          <SpecialExpenses mainservice={this.mainService} />
          <Stamps mainservice={this.mainService} />
        </div>
      }
      {
        this.state.mode === Mode.analysis &&
        <Analysis mainservice={this.mainService} />
      }
      {
        this.state.mode === Mode.editClient &&
        <ClientEdit mainservice={this.mainService} clientId={this.state.clientId} />
      }

    </>
  }
}
