import React from 'react'
import MainService from '../../services/MainService'
import SpecialExpenseDTO from '../../DTO/SpecialExpenseDTO'
// import InputA from '../InputA/InputA'
import { Setter } from '../../services/ComService'
import { printDateTime, editDateTimeByString } from '../../services/DateService'
import { Edit, Trash } from 'react-feather'

enum Mode {
  standard,
  edit
}
type Props = {
  mainservice: MainService,
  expense: SpecialExpenseDTO
}

type State = {
  newEName: string,
  newECost: number,
  newEDate: Date,
  mode: Mode
}

export default class SpecialExpenses extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      newEName: props.expense.name,
      newECost: props.expense.cost,
      newEDate: props.expense.date,
      mode: Mode.standard
    }
  }

  renderEdit() {
    if (this.state.mode != Mode.edit) { return <></>}
    return <div>
      <div>Name</div>
      <input
        value={this.state.newEName}
        onChange={(e) => {
          this.setState({
            newEName: e.target.value
          })
        }}
      />
      <div>Kosten</div>
      <input
        value={this.state.newECost}
        onChange={(e) => {
          this.setState({
            newECost: parseFloat(e.target.value)
          })
        }}
      />
      <div>Datum</div>
      <input
        value={printDateTime(this.state.newEDate)}
        onChange={(e) => {
          this.setState({
            newEDate: new Date(e.target.value)
          })
        }}
      />
      <button
        onClick={() => {
          const e = new SpecialExpenseDTO({
            clientId: this.props.expense.clientId,
            name: this.state.newEName,
            date: printDateTime(this.state.newEDate),
            cost: this.state.newECost
          })
          e.save().then((ne) => {
            this.setState({
              newEName: '',
              newECost: 0,
              newEDate: new Date(),
              mode: Mode.standard
            })
          })
        }}
      >Übernehmen</button>
    </div>
  }

  renderItem() {
    if (this.state.mode != Mode.standard) { return <></>}
    const e = this.props.expense
    return <div
      key={e.id}
    >
      <div>{e.name}</div>
      <div>{e.cost}</div>
      <button
        onClick={() => {
          this.setState({
            mode: Mode.edit
          })
        }}
      ><Edit /></button>
    </div>
  }

  render() {
    return <div className='expense'>
      { this.renderEdit() }
      { this.renderItem() }
    </div>
  }
}
