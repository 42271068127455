// import config from '../config.json'
import { Getter, Setter } from '../services/ComService'
import ProjectDTO, {IProject} from './ProjectDTO'
import ClientDataDTO, {IClientData} from './ClientDataDTO'

export default class ClientDTO {
  public id: number
  public name: string
  public projects: ProjectDTO[]
  public data: ClientDataDTO[] = []

  constructor(data: IClient) {
    this.id = 0
    this.name = ''
    this.projects = []
    if (data.clientData) {
      this.data = data.clientData.map((d) => new ClientDataDTO(d, data.id))
    }
    this.initData(data)
  }

  private initData(data: IClient) {
    this.id = data.id || 0
    this.name = data.name || ''
    console.log('initClientData', data)
    if (data.projects) {
      this.projects = data.projects.map((p) => new ProjectDTO(p))
    }
  }

  public get() {
    return {
      id: this.id,
      name: this.name
    }
  }

  public async publicGetFromServer() {
    const response = await Getter('client/' + this.id)
    this.initData(response)
  }

  public async switchId(id: number) {
    this.id = id
    await this.publicGetFromServer()
    return this.get()
  }

  public async addProject(data: IProject) {
    const client: IClient = await Setter(`client/${this.id}/project/add/`, data)
    this.initData(client)
    return this.projects
  }

  public async remove() {
    await Getter(`client/${this.id}/remove`)
  }

  public async addDataItem(data: IClientData) {
    const clientDataItem = new ClientDataDTO(data, this.id)
    await clientDataItem.saveToServer()
  }

  public getDataItemValue(name: string): string {
    const dI = this.getDataItem(name)
    console.log('getDataItemValue', dI)
    if (dI) {
      return `${dI.value}`
    }
    return ''
  }

  public getDataItem(name: string): ClientDataDTO | undefined {
    console.log('this.data', this.data)
    return this.data.find((d) => d.name === name)
  }
}

export interface IClient {
  id?: number,
  name?: string,
  projects?: IProject[],
  clientData?: IClientData[]
}
