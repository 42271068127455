// import config from '../config.json'
import UserDTO from '../DTO/UserDTO'

export default class MainService {
  public user: UserDTO
  public broadcastReceiver = new Map()

  constructor() {
    this.user = new UserDTO()
  }

  // Send Broadcast to all intrested parties:
  public broadcast(key: string, value: any) {
    this.broadcastReceiver.forEach((bR, bRKey: string) => {
      if (bR && bR.fkt && bR.self) {
        bR.fkt(key, value, bR.self)
      } else {
        this.unregisterBroadcast(bRKey)
      }
    })
  }

  public registerToBroadcast(key: string, fkt: any, self: any) {
    this.broadcastReceiver.set(key, {
      fkt: fkt,
      self: self
    })
  }

  public unregisterBroadcast(key: string) {
    this.broadcastReceiver.delete(key)
  }
}
