import { Getter, Setter } from '../services/ComService'

export default class ProjectDTO {
  public id: number
  public name: string
  public time: number
  public clientId: number = -1

  constructor(data: IProject) {
    this.id = 0
    this.name = ''
    this.time = 0
    this.initData(data)
  }

  private initData(data: IProject) {
    if (data.id) {
      this.id = data.id
    }
    this.name = data.name || ''
    this.clientId = data.clientId || -1
  }

  public async publicGetFromServer() {
    let responseJson = await Getter('' + this.id)
    this.initData(responseJson)
  }

  public async startStamp() {
    await Getter(`project/${this.id}/stamp/add`)
  }

  public async rename(name: string) {
    this.name = name
    await Setter(`project/${this.id}/rename`, {name: name})
  }

  public addTime(start: Date, end: Date) {
    if (start && end) {
      this.time += end.getTime() - start.getTime()
    }
  }
}

export interface IProject {
  id?: number,
  name: string
  clientId?: number
}
