import { Getter, Setter } from '../services/ComService'
import { printDateTime, editDateTimeByString, printDateForIT } from '../services/DateService'

export default class StampDTO {
  id: number
  name: string
  start: Date | null
  end: Date | null
  projectId: number
  clientName: string
  clientId: number
  constructor(data: IStamp) {
    this.id = 0
    this.name = ''
    this.projectId = 0
    this.clientName = ''
    this.clientId = 0
    this.start = null
    this.end = null
    this.initData(data)
  }

  private initData(data: IStamp) {
    console.log('data', data)
    this.id = data.id
    this.projectId = data.projectId
    this.name = data.name || ''
    this.clientName = data.clientName || ''
    this.clientId = data.clientId || -1
    this.start = (data.start && data.start.date) ? new Date(data.start.date) : null
    this.end = (data.end && data.end.date) ? new Date(data.end.date) : null
  }

  public async publicGetFromServer() {
    let responseJson = await Getter('' + this.id)
    this.initData(responseJson)
  }

  public async setToServer() {

    let responseJson = await Setter('stamp/' + this.id + '/edit/', {
      start: (this.start === null) ? null : printDateForIT(this.start),
      end: (this.end === null) ? null : printDateForIT(this.end)
    })
    this.initData(responseJson)
  }

  public getStartFront(): string {
    if (this.start) {
      return printDateTime(this.start)
    }
    return '-'
  }
  public getEndFront(): string {
    if (this.end) {
      return printDateTime(this.end)
    }
    return '-'
  }

  public setStartByString(s: string) {
    this.start = editDateTimeByString(this.start, s)
    this.setToServer()
  }

  public setEndByString(s: string) {
    this.end = editDateTimeByString(this.end, s)
    this.setToServer()
  }

  public async delete() {
    await Getter(`stamp/${this.id}/delete`)
  }
}

export interface IStamp {
  id: number,
  projectId: number,
  name: string,
  clientName: string,
  clientId: number,
  start: {date: string} | null,
  end: {date: string} | null
}
