import { Setter } from '../services/ComService'

export default class ClientDataDTO {
  public id: number
  public name: string
  public value: string | number
  public clientId: number = -1
  constructor(data: IClientData, clientId?: number) {
    this.id = data.id || -1
    this.name = data.name
    this.value = data.value
    if (data.clientId !== undefined) {
      this.clientId = data.clientId
    } else if (clientId !== undefined) {
      this.clientId = clientId
    }
  }
  get() {
    return {
      id: this.id,
      name: this.name,
      value: this.value
    }
  }
  async saveToServer() {
    if (this.clientId < 0) { return }
    await Setter(`client/${this.clientId}/DataItem/add`, this.get())
  }
}

export interface IClientData {
  id?: number
  name: string
  value: string | number
  clientId?: number
}
