import React from 'react'
import StampDTO from '../../DTO/StampDTO'
import MainService from '../../services/MainService'
import InputA from '../InputA/InputA'
import { Getter } from '../../services/ComService'
import { Edit, Trash, Play } from 'react-feather'

enum Mode {
  show,
  edit
}

type Props = {
  mainservice: MainService,
  stamp: StampDTO
}

type State = {
  mode: Mode
}

export default class Stamp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      mode: Mode.show
    }

  }

  renderEdit() {
    return <div className='stamp'>
      <div className='stampName'>{this.props.stamp.name}</div>
      <div className='clientName'>{this.props.stamp.clientName}</div>
      <div>
      Start:
      <InputA
        mainservice={this.props.mainservice}
        returnVal={(rv, self) => {
          self.setState({mode: Mode.show})
          self.props.stamp.setStartByString(rv)
        }}
        value={this.props.stamp.getStartFront()}
        parent={this}
      />
      </div>
      <div>
      Ende:
      <InputA
        mainservice={this.props.mainservice}
        returnVal={(rv, self) => {
          self.setState({mode: Mode.show})
          self.props.stamp.setEndByString(rv)
        }}
        value={this.props.stamp.getEndFront()}
        parent={this}
      />
      </div>
      <button
        onClick={() =>{
          this.setState({mode: Mode.show})
        }}
      >
        Abbrechen
      </button>
    </div>
  }

  renderShow() {
    return <div className='stamp w3-card-4 w3-margin'>
      <div className='clientName w3-container w3-blue'>
        {this.props.stamp.clientName}
      </div>
      <div className='w3-container'>
        <h2 className='stampName'>{this.props.stamp.name}</h2>
      </div>
      <div className='w3-container'>
        <span className='start'>{this.props.stamp.getStartFront()}</span>
        -
        <span className='end'>{this.props.stamp.getEndFront()}</span>
      </div>
      <div className='w3-container w3-blue'>
        <Edit onClick={() => {
          this.setState({mode: Mode.edit})
        }}/>
        <Trash onClick={() => {
          const id = this.props.stamp.id
          if (window.confirm('Stamp wirklich löschen?')) {
            this.props.stamp.delete().then(() => {
              this.props.mainservice.broadcast('stampRemoved', id)
            })
          }
          // this.trash()
        }}/>
        <Play onClick={() => {
          const projectId = this.props.stamp.projectId
          Getter(`project/${projectId}/stamp/add`).then(() => {
            this.props.mainservice.broadcast('stampStarted', projectId)
          })
        }}/>
      </div>
    </div>
  }

  render() {
    console.log('stamp', this.props.stamp)
    if (this.state.mode === Mode.show) {
      return this.renderShow()
    }
    return this.renderEdit()

  }
}
