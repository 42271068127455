import React from 'react'
import ClientDTO from '../../DTO/ClientDTO'
import MainService from '../../services/MainService'
import InputA from '../InputA/InputA'
import Client from './Client'
import './Clients.scss'

type Props = {
  mainservice: MainService
}
type State = {
  clients: ClientDTO[],
  addMode: boolean,
  count: number,
  activeClient: number
}

export default class Clients extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      clients: [],
      addMode: false,
      count: 0,
      activeClient: -1
    }
  }

  componentDidMount() {
    this.getContentFromServer(this)
    this.props.mainservice.registerToBroadcast(
      'clientsComponent',
      this.broadcastReceiver,
      this
    )
  }

  componentWillUnmount() {
    this.props.mainservice.unregisterBroadcast('clientsComponent')
  }

  broadcastReceiver(key: string, value: any, self: any) {
    console.log('clients got brodcast: ', key, value)
    switch(key) {
      case 'selectClient':
        self.setState({activeClient: value as number})
        break
      case 'clientRemoved':
        self.getContentFromServer(self)
        break
    }
  }

  async getContentFromServer(self: any) {
    const clients = await self.props.mainservice.user.getClients()
    self.setState({clients: clients})
  }

  renderAddClient() {
    if (this.state.addMode) {
      return <div>
        <InputA
          mainservice={this.props.mainservice}
          returnVal={(value: string) => {
            this.setState({addMode: false})
            this.props.mainservice.user.addClient({name: value}).then((cs: ClientDTO[]) => {
              this.setState({clients: cs, count: cs.length})
              this.forceUpdate()
            })
          }}
          parent={this}
          />
      </div>
    }
    return <div>
      <button
        onClick={() => {this.setState({addMode: true})}}
        >Hinzufügen</button>
    </div>
  }

  renderList() {
    return this.state.clients.sort((a, b) => (a.name < b.name) ? -1 : 1).map((c: ClientDTO) => <Client
      key={c.id}
      className={(c.id === this.state.activeClient) ? 'active' : ''}
      mainservice={this.props.mainservice}
      client={c}
      />
    )
  }

  render() {
    return <div className='clients w3-col s12 m2'>
      <h2>Kunden</h2>
      {
        this.renderAddClient()
      }
      {
        this.renderList()
      }

    </div>
  }
}
