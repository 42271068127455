import React from 'react'
import MainService from '../../services/MainService'
import SpecialExpenseDTO from '../../DTO/SpecialExpenseDTO'
import SpecialExpense from './SpecialExpense'
// import InputA from '../InputA/InputA'
import { Setter } from '../../services/ComService'
import { printDateTime, editDateTimeByString } from '../../services/DateService'

type Props = {
  mainservice: MainService
}

type State = {
  clientId: number,
  expenses: SpecialExpenseDTO[],
  addMode: boolean,
  newEName: string,
  newECost: number,
  newEDate: Date,
  editEId: number
}

export default class SpecialExpenses extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      clientId: -1,
      expenses: [],
      addMode: false,
      newEName: '',
      newECost: 0,
      newEDate: new Date(),
      editEId: -1
    }
  }

  componentDidMount() {
    this.props.mainservice.registerToBroadcast(
      'Expenses',
      (key: string, value: any, _self: any) => {
        console.log('component broadcast receive, ', key, value)
        switch(key) {
          case 'selectClient':
            this.setState({clientId: value})
            this.getFromServer()
            break
        }
      },
      this
    )
    this.getFromServer()
  }

  componentWillUnmount() {
    this.props.mainservice.unregisterBroadcast('Expenses')
  }

  async getFromServer() {
    let start = new Date()
    start.setDate(start.getDate() - 60)
    const result: {expense: any[]} = await Setter(`client/${this.state.clientId}/expenses`, {
      start: printDateTime(start),
      end: printDateTime(new Date())
    })
    console.log('wtf', result.expense)
    console.log('got this from server', result.expense.map((e) => new SpecialExpenseDTO(e)))
    this.setState({
      expenses: result.expense.map((e) => new SpecialExpenseDTO(e))
    })
  }

  renderAdd() {
    if (this.state.clientId < 0) { return <></> }
    if (this.state.addMode) {
      return <div>
        <div>Name</div>
        <input
          value={this.state.newEName}
          onChange={(e) => {
            this.setState({
              newEName: e.target.value
            })
          }}
        />
        <div>Kosten</div>
        <input
          value={this.state.newECost}
          onChange={(e) => {
            this.setState({
              newECost: parseFloat(e.target.value)
            })
          }}
        />
        <div>Datum</div>
        <input
          value={printDateTime(this.state.newEDate)}
          onChange={(e) => {
            this.setState({
              newEDate: new Date(e.target.value)
            })
          }}
        />
        <button
          onClick={() => {
            const e = new SpecialExpenseDTO({
              clientId: this.state.clientId,
              name: this.state.newEName,
              date: printDateTime(this.state.newEDate),
              cost: this.state.newECost
            })
            e.save().then(() => {
              this.setState({
                addMode: false,
                newEName: '',
                newECost: 0,
                newEDate: new Date()
              })
            })
            this.getFromServer()
          }}
        >Anlegen</button>
      </div>
    }
    return <div>
      <button
        onClick={() => {this.setState({addMode: true})}}
        >Hinzufügen</button>
    </div>
  }

  renderList() {
    console.log('clientId', this.state.clientId)
    if (this.state.clientId < 0) { return <></> }
    console.log('render List', this.state.expenses)
    return this.state.expenses.map((e, i) => {
      console.log('render list item', e)
      return <SpecialExpense
        key= {i}
        mainservice={this.props.mainservice}
        expense={e}
      />
    })
  }

  render() {
    return <div className='expenses w3-col s12 m1 w3-hide'>
      <h2>Expenses</h2>
      { this.renderAdd() }
      { this.renderList() }
    </div>
  }
}
