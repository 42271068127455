import React from 'react'
import InputA from '../InputA/InputA'
import Stamp from '../Stamps/Stamp'
import MainService from '../../services/MainService'
import { print1decimals, print2decimals, round21decimals, round22decimals, roundMilliseconds2QuarterHours } from '../../services/NumberService'
import { printCurrentDate, printDateFormat, msToHoursMinutes, msToHours } from '../../services/DateService'
import StampDTO from '../../DTO/StampDTO'
import ProjectDTO from '../../DTO/ProjectDTO'
import ClientDTO, { IClient } from '../../DTO/ClientDTO'
import { editDateTimeByString, printDateTime, startOfDay } from '../../services/DateService'
import './bill.scss'
import Logo from '../../static/gfx/hauke-button-pixel-logo.svg'

enum Mode {
  standard
}

enum Tab {
  stamps,
  timePerProject,
  bill
}

type Day = {
  id: number,
  day: number,
  name: string,
  duration: number
}

type Props = {
  mainservice: MainService
}

type State = {
  mode: Mode,
  startDate: Date,
  endDate: Date,
  stamps: StampDTO[],
  clients: ClientDTO[],
  clientId: number,
  projects: ProjectDTO[],
  tab: Tab,
  billNumber: string,
  customDate: string
}

export default class Analysis extends React.Component<Props, State> {
  allStamps: StampDTO[] = []
  allProjects: ProjectDTO[] = []
  days: Day[] = []
  constructor(props: Props) {
    super(props)
    // const today = new Date()
    let initStart = new Date()
    initStart.setDate(1)
    let initEnd = new Date()
    initEnd.setFullYear(initStart.getFullYear(), initStart.getMonth() + 1, 0)
    // initEnd.setMonth(initEnd.getMonth() + 1)
    // initEnd.setDate(1)
    initEnd.setHours(23)
    initEnd.setMinutes(59)
    this.state = {
      mode: Mode.standard,
      startDate: startOfDay(initStart),
      endDate: initEnd,
      stamps: [],
      clients: [],
      clientId: -1,
      projects: [],
      tab: Tab.timePerProject,
      billNumber: '',
      customDate: ''
    }
  }

  async getData() {
    const allClients = await this.props.mainservice.user.getClients()
    const stamps: StampDTO[] = await this.props.mainservice.user.getStamps({
      start: this.state.startDate,
      end: this.state.endDate
    })
    console.log('--stamps', stamps)
    // get client list:
    let clients: ClientDTO[] = []
    let projects = new Map()
    stamps.forEach((s) => {
      if (!clients.some((c) => c.id === s.clientId)) {
        let client = allClients.find((c) => c.id === s.clientId) || new ClientDTO({name: s.clientName, id: s.clientId})
        console.log('addCLient', client, client.id, s.clientId, s.clientName)
        clients.push(client)
      }
      if (!projects.has(s.projectId)) {
        projects.set(s.projectId, new ProjectDTO({
          id: s.projectId,
          name: s.name,
          clientId: s.clientId
        }))
      }
      const project = projects.get(s.projectId)
      console.log('s', s)
      project.addTime(s.start, s.end)
    })
    let projects_: ProjectDTO[] = []
    projects.forEach((p) => projects_.push(p))
    console.log('projects', projects_)
    this.allProjects = projects_
    this.allStamps = stamps
    this.setState({
      clients: clients,
      stamps: stamps,
      projects: projects_
    })
  }

  renderDateSelector() {
    return <div className='dateSelector'>
      <InputA
        returnVal={(rv) => {
          this.setState({startDate: editDateTimeByString(null, rv)})
        }}
        value={printDateTime(this.state.startDate)}
      /> bis <InputA
        returnVal={(rv) => {
          console.log('rv:', rv)
          this.setState({endDate: editDateTimeByString(null, rv)})
        }}
        value={printDateTime(this.state.endDate)}
      />
      <button onClick={() => {this.getData()}}>Los</button>
    </div>
  }

  renderClientSelector() {
    return <div className='clientSelector'>
      {
        this.state.clientId > -1 &&
        <button
          onClick={() => {
            this.setState({
              clientId: -1,
              stamps: this.allStamps,
              projects: this.allProjects
            })
          }}
        >Filter aufheben</button>
      }
      {
        this.state.clientId > -1 &&
        <>
          <div>Rechnung generieren <InputA
            returnVal={(rv) => {
              this.setState({
                billNumber: rv
              })
            }}
          /></div>
          <div>Custom Datum <InputA
            returnVal={(rv) => {
              this.setState({
                customDate: rv
              })
            }}
          /></div>
        </>
      }
      {
        this.state.clients.map((c: ClientDTO) => <div
          key={c.id}
          className={`client ${(c.id === this.state.clientId) ? 'active': ''}`}
          onClick={() => {
            this.setState({
              clientId: c.id,
              stamps: this.allStamps.filter((s) => s.clientId === c.id),
              projects: this.allProjects.filter((p) => p.clientId === c.id)
            })
          }}
        >
          <div className='clientName'>{c.name}</div>
        </div>)
      }
    </div>
  }

  renderStamps() {
    return <div className='stampsPreview'>
      {
        this.state.stamps.map((s: StampDTO) => <Stamp
          key={s.id}
          mainservice={this.props.mainservice}
          stamp={s}
        />)
      }
    </div>
  }

  renderTimePerProject() {
    let timeTotal = 0
    this.state.projects.forEach(p => timeTotal += p.time)
    return <div className='timePerProject'>
      <div>Gesamtzeit: {print1decimals(timeTotal / 1000 / 60 / 60)} h</div>
      {
        this.state.projects.map((p: ProjectDTO) => <div
          key={p.id}
          className='project'
        >
          <div className='projectName'>{p.name}</div>
          <div className='timeConsumed'>{print1decimals(p.time / 1000 / 60 / 60)} h</div>
        </div>)
      }
    </div>
  }

  calc(name: string, v1?: number, v2?: number): number {
    let totalNetto = 0

    if (name.search(/^total/) > -1) {
      const client = this.state.clients.find((c) => c.id === this.state.clientId)
      if (!client) { return -1 }
      const hourlyPay = parseFloat(client.getDataItemValue('hourlyPay').replace(',', '.'))
      this.days.forEach(d => {
        totalNetto += (msToHours(d.duration) * hourlyPay)
      })
    }
    switch(name) {
      case 'itemTotal':
        if(v1 && v2) {
          return round22decimals(v1 * v2)
        }
        break
      case 'totalwithtax':
        return totalNetto * 1.19
      case 'totaltax':
        return totalNetto * 0.19
      case 'totalnetto':
        return totalNetto
    }
    return -1
  }

  gatherDays(stamps: StampDTO[]): Day[] {
    type StampDayTotal = {
      id: number,
      day: number,
      name: string,
      duration: number
    }
    let days: number[] = []
    let sdts: StampDayTotal[] = []
    stamps.forEach((s: StampDTO) => {
      if (!s?.start || !s?.end) { return }
      const day = s.start.getDate()
      // Tag bekannt machen, wenn noch nicht geschehen
      if (!days.some((d) => d === day)) {
        days.push(day)
      }
      // Länge errechnen
      const duration = s.end.getTime() - s.start.getTime()
      // entweder neuen Eintrag, oder Eintrag erweitern:
      let currentItem = sdts.find((t) => t.name === s.name && t.day === day)
      if (currentItem) {
        currentItem.duration = currentItem.duration + duration
      } else {
        sdts.push({
          id: s.projectId,
          day: day,
          name: s.name,
          duration: duration
        })
      }
    })
    return sdts.map(s => {
      return {
        id: s.id,
        day: s.day,
        name: s.name,
        duration: roundMilliseconds2QuarterHours(s.duration)
      }
    }).sort((a, b) => a.day - b.day)
  }

  renderLetter() {
    const myAdress = ['Hauke Huchzermeyer', 'Kampstraße 71', '30629 Hanover']
    const client = this.state.clients.find((c) => c.id === this.state.clientId)
    const thingName = <span>Leistungszeitraum {printDateFormat(this.state.startDate)} bis {printDateFormat(this.state.endDate)}</span>
    if (!client) {
      return <>Kein Client angegeben</>
    }
    const clientAdress = client.getDataItemValue('adress')
    const workdoneTableHeader = ['Menge', 'Einheit', 'Beschreibung', 'Einzelpreis (netto)', 'Gesamtpreis (netto)']
    const hoursdoneTableHeader = ['Tag', 'Aufgabe', 'Stunden']
    const hourlyPay = client.getDataItemValue('hourlyPay')
    this.days = this.gatherDays(this.state.stamps)
    console.log('clientAdress', clientAdress)
    console.log('client:', client)
    console.log('STAMPS', this.state.stamps)
    console.log('DAYS', this.days)
    return <div className='letterHeaders'>
      <div className='MyLogo'>
        <img src={Logo} />
      </div>
      <div
        className='MyAddress'
        dangerouslySetInnerHTML={{ __html: myAdress.join('<br />') }}
      ></div>
      <div className='ToField'>
        <div className='MySmallAddress'>
          {myAdress.join(' - ')}
        </div>
        <div className='ThereAddress' dangerouslySetInnerHTML={{ __html: clientAdress }}></div>
      </div>
      <div className='box'>
        <div className='BillNumberBox billBox'>
          <div className='billBoxHeadline'>Rechnungsnummer</div>
          <div className='billBoxContent'>{this.state.billNumber}</div>
        </div>
        <div className='BillDate billBox'>
          <div className='billBoxHeadline'>Datum</div>
          <div className='billBoxContent'>{this.state.customDate || printCurrentDate()}</div>
        </div>
      </div>
      <div className='subject'>
        Rechnung, {thingName}
      </div>
      <div className='billTextContent'>
        <p>Sehr geehrte Damen und Herren,</p>
        <p>für meine Tätigkeit im {thingName} stelle ich {print2decimals(this.calc('totalwithtax'))} EUR in Rechnung.</p>
        <p>Ich bitte Sie den Rechnungsbetrag in den den folgenden 14 Tagen auf das unten angegebene Konto zu überweisen.</p>
        <p>Mit freundlichen Grüßen,</p>
        <p></p>
        <p>Hauke Huchzermeyer</p>
        <p className='attachmentdescription'>
          Anlagen:<br />
          Leistungsübersicht<br />
          Stundenübersicht<br />
        </p>
        <p className='attachmentdescription'>
          PS.: Bitte nutzen Sie meine neue Bankverbindung
        </p>
      </div>
      <div className='billFooter'>
        <div className='footerBox'>
          <div
            className=''
            dangerouslySetInnerHTML={{ __html: myAdress.join('<br />') }}
          ></div>
        </div>
        <div className='footerBox'>
          <div>+49 (0) 151 58 118 427</div>
          <div>hauke@huchzermeyer-it.de</div>
          <div>Steuernummer: 24/119/05976</div>
        </div>
        <div className='footerBox'>
          <div>Bankverbindung:</div>
          <div>IBAN: DE56 1101 0101 5995 3619 05</div>
          <div>BIC: SOBKDEB2XXX</div>
          <div>Bank: SOLARIS</div>
        </div>
      </div>
      <div className='pageBreak'></div>
      <div className='workDoneTable'>
        <h2 className='letterH2'>Leistungsübersicht Huchzermeyer, {thingName}</h2>
        <table style={{width: '100%'}}>
          <thead>
            <tr>
              {workdoneTableHeader.map((w, index) => <th key={index}>{w}</th>)}
            </tr>
          </thead>
          <tbody>
            {
              this.state.projects.map((p: ProjectDTO) => {
                // TODO We need the sum over all days because they are quantiziesed to quarter hours
                // const timeConsumed = p.time / 1000 / 60 / 60
                console.log('PROJECT', p)
                console.log('DAYS', this.days)
                console.log('DAYSFilter', this.days.filter(d => d.id === p.id))
                let timeComsumedRaw: number = 0
                this.days.filter(d => d.id === p.id).forEach((a) => { timeComsumedRaw += a.duration })
                const timeConsumed = timeComsumedRaw / 1000 / 60 / 60
                return <tr
                  key={p.id}
                  className='projectBill'
                >
                  <td className='amount'>{msToHoursMinutes(timeComsumedRaw)}</td>
                  <td className='unit'>Std.</td>
                  <td className='projectName'>{p.name}</td>
                  <td className='costPerUnit'>{hourlyPay} EUR</td>
                  <td className='costNetto lastCol'>{print2decimals(this.calc('itemTotal', parseFloat(hourlyPay.replace(',', '.')), timeConsumed))} EUR</td>
                </tr>
              })
            }
            <tr className='resultNetto'><td colSpan={4}>Nettobetrag</td><td className='lastCol'>{print2decimals(this.calc('totalnetto'))} EUR</td></tr>
            <tr className='resultTax'><td colSpan={4}>Umsatzsteuer 19%</td><td className='lastCol'>{print2decimals(this.calc('totaltax'))} EUR</td></tr>
            <tr className='resultTotal'><td colSpan={4}>Rechnungsbetrag</td><td className='lastCol'>{print2decimals(this.calc('totalwithtax'))} EUR</td></tr>
          </tbody>
        </table>
      </div>
      <div className='pageBreak'></div>
      <div className='workDoneTable'>
        <h2 className='letterH2'>Stundenübersicht Huchzermeyer, {thingName}</h2>
        <table style={{width: '100%'}}>
          <thead>
            <tr>
              {hoursdoneTableHeader.map((w, index) => <th key={index}>{w}</th>)}
            </tr>
          </thead>
          <tbody>
            {
              this.days.map((d, index) => {
                // const timeConsumed = round21decimals(p.time / 1000 / 60 / 60)
                return <tr
                  key={index}
                  className='projectBill'
                >
                  <td className='amount'>{(index === 0 || this.days[index - 1].day !== d.day) ? d.day+'' : ''}</td>
                  <td className='projectName'>{d.name}</td>
                  <td className='costPerUnit'>{msToHoursMinutes(d.duration)}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  }

  render () {
    return <div className='analysis'>

      {
        this.renderDateSelector()
      }
      {
        this.renderClientSelector()
      }
      {
        [
          {
            name: 'Stamps',
            target: Tab.stamps
          },
          {
            name: 'Times',
            target: Tab.timePerProject
          },
          {
            name: 'Bill',
            target: Tab.bill
          }
        ].map((b) => <button
          key={b.target}
          onClick={() => {
            this.setState({tab: b.target})
          }}
        >
          {b.name}
        </button>)
      }
      {
        this.state.tab === Tab.stamps &&
        this.renderStamps()
      }
      {
        this.state.tab === Tab.timePerProject &&
        this.renderTimePerProject()
      }
      {this.state.tab === Tab.bill &&
        this.renderLetter()
      }
    </div>
  }
}
