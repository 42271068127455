import React from 'react'

type ReturnValType = (rv: string, parent?: any) => void

type Props = {
  mainservice?: any
  returnVal: ReturnValType
  value?: string
  parent?: any
  type?: string
  equalizeFkt?: (v: string) => string
}

type State = {
  editValue1Status: string,
  type: string,
  editInProgress: boolean
}

class InputA extends React.Component<Props, State> {
  mainService: any
  returnVal: ReturnValType
  state: State
  parent: any
  constructor(props: Props) {
    super(props)
    // this.mainService = props.mainservice
    this.returnVal = props.returnVal
    this.parent = props.parent
    const value = (props.value) ? props.value.replace(/<br \/>/g, '\n') : ''
    this.state = {
      editValue1Status: value,
      type: props.type || 'input',
      editInProgress: false
    }
  }
  sendResult() {
    let sendVal = this.state.editValue1Status
    if (this.props.equalizeFkt) {
      sendVal = this.props.equalizeFkt(sendVal)
    }
    this.returnVal(sendVal.replace(/\n/g, '<br />'), this.parent)
    this.setState({
      editInProgress: false
    })
  }
  render() {
    return <>
      {
        this.state.type === 'input' &&
        <input
        value={this.state.editValue1Status}
        onChange={(event) => {
          this.setState({
            editValue1Status: event.target.value,
            editInProgress: true
          })
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            this.sendResult()
          }
        }}
        />
      }
      {
        this.state.type === 'textArea' &&
        <textarea
        value={this.state.editValue1Status}
        onChange={(event) => {
          this.setState({
            editValue1Status: event.target.value,
            editInProgress: true
          })
        }}
        />
      }
      <button
        style={{
          opacity: (this.state.editInProgress) ? 1 : 0
        }}
        onClick={() => {
          this.sendResult()
        }}
      >ok</button>
    </>
  }
}

export default InputA
