import React from 'react'
// import ClientDTO from '../../DTO/ClientDTO'
import StampDTO from '../../DTO/StampDTO'
// import UserDTO from '../../DTO/UserDTO'
import MainService from '../../services/MainService'
import Stamp from './Stamp'
import './stamps.scss'

type Props = {
  mainservice: MainService
}
type State = {
  stamps: StampDTO[]
}

export default class Stamps extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      stamps: []
    }

  }

  componentDidMount() {
    this.props.mainservice.registerToBroadcast(
      'stampsComponent',
      this.broadcastReceiver,
      this
    )
    this.getContentFromServer()
  }

  componentWillUnmount() {
    this.props.mainservice.unregisterBroadcast('stampsComponent')
  }


  broadcastReceiver(key: string, value: any, self: any) {
    console.log('stamps got broadcast: ', key, value)
    switch (key) {
      case 'stampStarted':
      case 'stampRemoved':
      case 'stop':
      self.getContentFromServer()
    }
  }

  getContentFromServer() {
    this.props.mainservice.user.getStamps({}).then((stamps: StampDTO[]) => {
      this.setState({stamps: stamps})
    })
  }

  renderStampList() {
    console.log('stamplist', this.state.stamps)
    return this.state.stamps.map((s) => <Stamp key={s.id} mainservice={this.props.mainservice} stamp={s} />)

  }

  render() {
    return <div className='stamps w3-col s12 m4'>
      <h2>Stamps</h2>
      {this.renderStampList()}
    </div>
  }
}
