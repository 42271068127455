import React from 'react'
import ClientDTO from '../../DTO/ClientDTO'
import MainService from '../../services/MainService'
import './Client.scss'
import { Edit } from 'react-feather'

enum Mode {
  standard,
  edit
}

type Props = {
  mainservice: MainService,
  client: ClientDTO,
  className: string
}
type State = {
  client: ClientDTO,
  mode: Mode
}

export default class Client extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      client: props.client,
      mode: Mode.standard
    }
  }


  render() {
    return <div className={`client ${this.props.className}`} key={this.state.client.id}>
      <span
        onClick={() => {
          this.props.mainservice.broadcast('selectClient', this.state.client.id)
        }}>
        {this.state.client.name}
      </span>
      {
        this.state.mode === Mode.standard &&
        <Edit
        onClick={() => {
          this.props.mainservice.broadcast('editClient', this.state.client.id)
        }}
        />
      }
    </div>
  }
}
