export function printDateTime(d: Date): string {
  return `${TwoDigits(d.getDate())}.${TwoDigits(d.getMonth() + 1)}.${d.getFullYear()} ${TwoDigits(d.getHours())}:${TwoDigits(d.getMinutes())}`
}

export function editDateTimeByString(d: Date | null, s: string) {
  if (!d) {
    d = new Date()
  }
  const scope = /([0-9]{2})\.([0-9]{2})\.() ([0-9]{2}):([0-9]{2})/
  const scopeWithYear = /([0-9]{2})\.([0-9]{2})\.([0-9]{4}) ([0-9]{2}):([0-9]{2})/

  const sr = s.match(scopeWithYear) || s.match(scope)
  if (!sr) { return d }
  const year = sr[3]
  if (year) {
    d.setFullYear(parseInt(year, 10))
  }
  d.setMonth(parseInt(sr[2], 10) - 1)
  d.setDate(parseInt(sr[1], 10))
  d.setHours(parseInt(sr[4], 10))
  d.setMinutes(parseInt(sr[5], 10))
  return d
}

export function printDateForIT(d: Date): string {
  // 2021-06-28T21:59:59.275Z
  // 2021-06-28T21:59:59.778Z
  // return `${d.getFullYear()}-${TwoDigits(d.getMonth())}-${TwoDigits(d.getDate())}T${TwoDigits(d.getHours())}-${TwoDigits(d.getMinutes())}-00.00Z`
  return `${d.getFullYear()}-${TwoDigits(d.getMonth() + 1)}-${TwoDigits(d.getDate())} ${TwoDigits(d.getHours())}:${TwoDigits(d.getMinutes())}:${TwoDigits(d.getSeconds())}`
}

export function TwoDigits(i: number): string {
  if (i < 10 && i >= 0) {
    return `0${i}`
  }
  return `${i}`
}

export function startOfDay(d?: Date): Date {
  if (!d) {
    d = new Date()
  }
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  return d
}

export function endOfDay(): Date {
  let d = new Date()
  d.setHours(23)
  d.setMinutes(59)
  d.setSeconds(59)
  return d
}

export function printCurrentDate(): string {
  const d = new Date()
  return printDateFormat(d)
}

export function printDateFormat(d: Date): string {
  const day = TwoDigits(d.getDate())
  const month = TwoDigits(d.getMonth() + 1)
  const year = d.getFullYear()
  return `${day}.${month}.${year}`
}

export function msToHoursMinutes(i: number): string {
  let m = Math.floor(i / 1000 / 60)
  let h = Math.floor(m / 60)
  m = m - h * 60
  return `${TwoDigits(h)}:${TwoDigits(m)}`
}

export function msToHours(i: number): number {
  return i / 1000 / 60 / 60
}
