import React from 'react'
import ProjectDTO from '../../DTO/ProjectDTO'
import MainService from '../../services/MainService'
import './Project.scss'
import { Edit } from 'react-feather'

enum Mode {
  standard,
  edit
}

type Props = {
  mainservice: MainService,
  project: ProjectDTO,
  className: string
}
type State = {
  project: ProjectDTO,
  mode: Mode,
  name: string
}

export default class Project extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      project: props.project,
      mode: Mode.standard,
      name: props.project.name
    }

  }

  renderShow() {
    return <div className={`project ${this.props.className}`} key={this.state.project.id}>
      <span
        onClick={() => {
          this.state.project.startStamp().then(() => {
            this.props.mainservice.broadcast('stampStarted', this.state.project.id)
          })
        }}>
        {this.state.project.name}
      </span>
      <Edit
        onClick={() => {
          this.setState({
            mode: Mode.edit
          })
        }}
      />
    </div>
  }

  renderEdit() {
    return <div className={`project ${this.props.className}`} key={this.state.project.id}>
      <input
        value={this.state.name}
        onChange={(event) => {
          this.setState({name: event.target.value})
        }}
      />
      <button
        onClick={() => {
          this.state.project.rename(this.state.name).then(() => {
            this.setState({
              mode: Mode.standard
            })
          })
        }}
      >
        ok
      </button>
    </div>
  }

  render() {
    if (this.state.mode === Mode.edit) {
      return this.renderEdit()
    }
    return this.renderShow()
  }
}
