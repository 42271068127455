import React from 'react'
import MainService from '../../services/MainService'
import InputA from '../InputA/InputA'
import ClientDTO from '../../DTO/ClientDTO'
import { Trash } from 'react-feather'
import { Getter } from '../../services/ComService'

type Props = {
  mainservice: MainService,
  clientId: number
}

type State = {
  client: ClientDTO | null
}

export default class ClientEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {client: null}
  }

  componentDidMount() {
    this.getContentFromServer(this)
  }

  async getContentFromServer(self: any) {
    const client = await Getter(`client/${this.props.clientId}`)
    self.setState({client: new ClientDTO(client)})
  }

  render() {
    if (!this.state.client) {
      return <div>Lade ...</div>
    }
    const hourlyPay = this.state.client.getDataItemValue('hourlyPay')
    const adress = this.state.client.getDataItemValue('adress')
    return <div className='clientEdit w3-padding'>
      <div>
        Stundenlohn: <InputA
          returnVal={(rv: string) => {
            if (!this.state.client) { return }
            this.state.client.addDataItem({
              name: 'hourlyPay',
              value: rv
            })
          }}
          value={hourlyPay}
        />
      </div>

      <div>
        Postadresse: <InputA
          type='textArea'
          returnVal={(rv: string) => {
            if (!this.state.client) { return }
            this.state.client.addDataItem({
              name: 'adress',
              value: rv
            })
          }}
          value={adress}
        />
      </div>

      <div>
        Kunde Löschen <Trash
          onClick={() => {
            if(this.state.client &&
              window.confirm(`Client ${this.state.client.name} wirklich löschen?`)
            ) {
              this.state.client.remove().then(() => {
                this.props.mainservice.broadcast('clientRemoved', true)
              })
            }
          }}
        />
      </div>
    </div>
  }
}
