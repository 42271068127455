export function round22decimals(n: number, ceil?: boolean): number {
  if (ceil) {
    return Math.ceil(n * 100) / 100
  }
  return Math.round(n * 100) / 100
}

export function round21decimals(n: number, ceil?: boolean): number {
  if (ceil) {
    return Math.ceil(n * 10) / 10
  }
  return Math.round(n * 10) / 10
}

export function print2decimals(n: number, ceil?: boolean): string {
  let s = (ceil) ? (Math.ceil(n * 100) + '') : (Math.round(n * 100) + '')
  if (s.length < 2) {
    s = '00' + s
  } else if (s.length < 3) {
    s = '0' + s
  }
  return s.replace(/([0-9])([0-9]{2})$/, '$1,$2')
}

export function print1decimals(n: number, ceil?: boolean): string {
  let s = (ceil) ? (Math.ceil(n * 10) + '') : (Math.round(n * 10) + '')
  if (s.length < 2) {
    s = '0' + s
  }
  return s.replace(/([0-9])([0-9])$/, '$1,$2')
}

export function roundMilliseconds2QuarterHours(i: number): number {
  return Math.ceil(i / 1000 / 60 / 15) * 1000 * 60 * 15
}
