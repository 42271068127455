import React from 'react'
import ProjectDTO from '../../DTO/ProjectDTO'
import ClientDTO from '../../DTO/ClientDTO'
import InputA from '../InputA/InputA'
import Project from './Project'
import MainService from '../../services/MainService'
import './Projects.scss'

type Props = {
  mainservice: MainService
}
type State = {
  clientId: number,
  addMode: boolean,
  activeProject: number
}

export default class Projects extends React.Component<Props, State> {
  client = new ClientDTO({})
  constructor(props: Props) {
    super(props)
    this.state = {
      clientId: -1,
      addMode: false,
      activeProject: -1
    }
  }

  componentDidMount() {
    this.props.mainservice.registerToBroadcast(
      'projectsComponent',
      this.broadcastReceiver,
      this
    )
  }

  componentWillUnmount() {
    this.props.mainservice.unregisterBroadcast('projectsComponent')
  }


  broadcastReceiver(key: string, value: any, self: any) {
    console.log('projects got broadcast: ', key, value)
    if (key === 'selectClient') {
      console.log('--> its us!')
      self.client.switchId(value as number).then((clientData: any) => {
        console.log('new client Data', clientData)
        self.setState({clientId: value})
      })
    }
  }

  renderAddProject() {
    if (this.client.id < 1) {
      return <></>
    }
    if (this.state.addMode) {
      return <div>
        <InputA
          mainservice={this.props.mainservice}
          returnVal={(value: string) => {
            this.setState({addMode: false})
            this.client.addProject({name: value}).then((p: ProjectDTO[]) => {
              this.setState({addMode: false})
              this.forceUpdate()
            })
          }}
          parent={this}
          />
      </div>
    }
    return <div>
      <button
        onClick={() => {this.setState({addMode: true})}}
        >Hinzufügen</button>
    </div>
  }

  renderList() {
    return this.client.projects.sort((a, b) => b.id - a.id).map((c: ProjectDTO) => <Project
      key={c.id}
      className={(c.id === this.state.activeProject) ? 'active' : ''}
      mainservice={this.props.mainservice}
      project={c}
      />
    )
  }

  render() {
    return <div className='projects w3-col s12 m5'>
      <h2>Projekte ({this.client.name})</h2>
      {
        this.renderAddProject()
      }
      {
        this.renderList()
      }
    </div>
  }
}
