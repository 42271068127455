import config from '../config.json'
import ClientDTO, {IClient} from './ClientDTO'
import StampDTO, {IStamp} from './StampDTO'
import { Getter, Setter } from '../services/ComService'
import { startOfDay, endOfDay, printDateForIT } from '../services/DateService'

class UserDTO {
  apiPrefix = config.apiPrefix
  public id: number
  public name: string
  public email: string
  private clients: ClientDTO[]
  constructor() {
    this.id = 1
    this.name = ''
    this.email = ''
    this.clients = []
    //this.myInfo()
  }

  public async myInfo() {
    let responseJson = await Getter('')
    this.id = responseJson.id
    this.name = responseJson.name
    this.email = responseJson.email
    return responseJson
  }

  public async info() {
    let responseJson = await Getter('')
    this.id = responseJson.id
    this.name = responseJson.name
    this.email = responseJson.email
    return responseJson
  }
  public async login(name: string, password: string) {
    const encodedLogin = window.btoa(`${name}:${password}`)
    const credentials = `Basic ${encodedLogin}`
    let headers = new Headers()
    headers.append('Authorization', credentials)
    headers.append('crossDomain', 'true')
    headers.append('withCredentials', 'true')
    const requestOptions = {
      headers: headers
    }
    let result = await new Promise <IUser> ((resolve, reject) => {
      fetch(`${this.apiPrefix}user/${this.id}`, requestOptions).then((response: any) => {
        const data = response.json()
        this.id = data.id
        this.name = data.name
        this.email = data.email
        resolve(data)
      }).catch((err: any) => reject(err))
    })
    return result
  }

  public async logout() {

  }

  public async getClients(fresh?: boolean): Promise <ClientDTO[]> {
    if (this.clients.length === 0 || fresh) {
      const response = await Getter('clients/')
      if (response.clients) {
        this.clients = response.clients.map((c: IClient) => new ClientDTO(c))
      } else { return [] }
    }
    return this.clients
  }

  public async getStamps(data: {
    start?: Date,
    end?: Date,
    clientId?: number
  }): Promise <StampDTO[]> {
    const start = data.start || startOfDay()
    const end = data.end || endOfDay()
    console.log('start, end', start, end)

    const response = await Setter('stamps/', {
      start: printDateForIT(start),
      end: printDateForIT(end),
      clientId: data.clientId || -1
    })
    return response.stamps.map((s: IStamp) => new StampDTO(s))
  }

  public async addClient(client: IClient): Promise <ClientDTO[]> {
    await Setter('clients/add/', client)
    return await this.getClients(true)
  }
}

export interface IUser {
  id?: number
  name?: string
  email?: string
}

export default UserDTO
