import config from '../config.json'

export async function Getter(command: string) {
  const response = await fetch(`${config.apiPrefix}user/${config.myid}/${command}`)
  return await response.json()
}

export async function Setter(command: string, body: object) {
  const response = await fetch(`${config.apiPrefix}user/${config.myid}/${command}`, {
    method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  })
  return await response.json()
}
